@import "../styles/vars.scss";

// ---------------------- / BUTTONS / -------------------------
.btn_def {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 10px 0 14px;
  border-radius: 8px;
  height: 52px;
  background-color: $Green;
  border: solid 2px $Green;
  stroke-width: 1.7px;
  font-size: 17px;
  line-height: 95%;
  width: 100%;

  color: $White;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: $Dark;
    border: solid 2px $Dark;
  }

  svg {
    width: 26px;
    min-width: 26px;
    ;
  }



}

// - - - - - - - 

.btn_c_line {
  background-color: $White;
  border: solid 2px $Green;
  color: $Green !important;
}

.btn_c_grey {
  background-color: $Grey_00;
  border: solid 2px $Grey_00;
  color: $Green;
  justify-content: center;

}
.btn_c_line_grey {
  background-color: $White;
  border: solid 2px $Grey_05;
  color: $Grey_05;
  justify-content: center;

}
.resend_link_btn{
  margin-top: 20px;
}

.switch {
  flex-direction: row-reverse !important;
  padding: 0 12px 0 8px;
}

.btn_c_bg {
  background-color: $White;
  border: solid 2px $Green;
  color: $Green !important;
  background-image: url('../assets/icons/btn_bg.svg');

  &:hover {
    background-image: url('../assets/icons/btn_bg_hover.svg');
    color: $White;
  }
}

.btn_c_48 {
  height: 48px;
  padding: 0 8px 0 12px;
}

.btn_c_icn {
  padding: 0 8px;
  gap: 0;
  height: 50px;
  width: 50px;
  justify-content: center;
}

.btn_c_icn_number {
  padding: 0 2px 0 8px;
  gap: 1px;
}

.btn_c_big {
  height: 72px;
  font-size: 22px;
  font-weight: 450;
  border-radius: 12px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.btn_c_small {
  height: 38px;
  font-size: 16px;
  border-radius: 6px;
  gap: 4px;
  padding: 0 4px 0 8px;
  stroke-width: 2px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.renew {
  background-color: $Yellow;
  border: solid 2px $Yellow;


}

.small_link_btn {
  max-height: 26px;
  width: 58px;
  font-size: 16px;
  border-radius: 6px;
  gap: 4px;
  padding: 0 5px 0 7px;
  color: $Grey_04 !important;
  border-color: $Grey_02;

  svg {
    width: 13px;
    min-width: 13px !important;
    height: 13px;
    stroke-width: 3.4px;
  }
}


// ---------------------- / INPUTS / -------------------------

.input_def {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 6px;
  width: 100%;
  .input_focus{
    border-color: $Blue !important;
  }
  .input_box {
    border: 2px solid $Grey_03;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    position: relative;
    width: 100%;
    justify-content: space-between;
    background-color: $White;
    padding: 0 8px 0 12px;

    input {
      padding-right: 0px;
      border: none;
      height: 52px;
      width: 100%;
      outline: none;
      font-size: 17px;
      
      &::placeholder {
        color: $Grey_05;
      }
  
      &.error {
        border-color: $Red;
      }

      &:focus {
        .input_box{
        border-color: $Blue;
        }
      }

    }

    svg {
      width: 28px;
      color: $Grey_04;
      stroke-width: 1.5px;
    }

    .input-icon {
      padding: 0px;
      width: 34px;
      stroke-width: 1.5px;

      &.focused-icon {
        color: $Blue;
      }

      &.input-value-icon {
        cursor: pointer;
        transition: transform .2s ease-in-out;
        position: relative;
        display: block;
        top: 0;
        transform: rotateY(0);
      }

      &.input-value-icon:hover {
        color: $Blue;
        transform: rotate(45deg);
      }
    }
  }

  input:focus-within + .input_box {
    border-color: $Blue; /* Change to desired color */
  }

}



.input-disabled {
  background-color: #f6f6f6 !important;
}

.input_error {
  border: 2px solid #ff0000 !important
}

.dropdown-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.selected-index{
  background-color: $Grey_00;
}
.dropdown-select {
  padding-right: 48px;
  border: 2px solid $Grey_03;
  border-radius: 8px;
  height: 56px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  padding: 0 48px 0 16px;
  font-size: 16px;
  
}
.all-option::placeholder{
  color: $Grey_05 !important;
}
.all-option-focused::placeholder {
  color: $Grey_03 !important;
}
.textarea-label {
  font-size: 17px;
  padding-left: 6px;
  color: $Dark;
  transition: all 0.3s ease;
  color: $Grey_02;
}

.input-box-pretext {
  padding-left: 0px !important;
}

.left-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.right-icon {
  width: 30px;
  // height: 28px;
  margin-right: 6px;
  color: $Grey_04;
  stroke-width: 1.4px;
}

.right-icon.focused-icon {
  color: $Blue;
}

.dropdown-select.focused {
  border-color: $Blue;
}

.username-counter {
  padding: 0px 6px;
  color: $Grey_04;
}
.flag-box{
  display: flex;
}
.pref-logo {
  width: 26px;
  height: 26px;
}


// ---------------------- / PAGE TITLE / ------------------

.page_title_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  gap: 5px;
  color: $Green;
  font-size: 30px;
  width: 100%;

  .big_title {
    display: flex;
    flex-direction: row;
    justify-items: center;
    font-size: 27px;
    gap: 6px;
    border-left: 7px solid $Grey_01;
    padding-left: 12px;
    word-spacing: -9px;

    span {
      font-size: 27px;
      color: $Dark;
    }
  }
  .jp_text{  
    font-size: 25px;
  }
  h4 {
    font-size: 26px;
    font-weight: 450;
    padding: 0 8px;

  }

  h5 {
    font-size: 17px;
    font-weight: 450;
    color: $Dark;
    // margin-bottom: 16px;
    padding: 0 8px;
    text-wrap: wrap;

  }

}
.page_title_box_cont{
  display: flex;
  justify-content: space-between;
}
.pin-title {
  border: none !important;
  padding-left: 32px !important;
}

// ---------------------- / TAGS / -------------------------

.tag_def {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 12px;
  border: solid 2px $Green;
  color: $Green;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 6px;
}

.yellow_tag {
  color: $Yellow;
  border-color: $Yellow;
}

.grey_tag {
  color: $Grey_05;
  border-color: $Grey_05;
}

// ---------------------- / STATUSES / -------------------------

.status_def {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 7px;
  border: solid 2px $Green;
  color: $Green;
  border-radius: 6px;
  font-size: 17px;
  padding: 2px 8px;
  margin-top: 16px;

  span {
    color: $Dark;
  }
}

.expired_status {
  color: $Red;
  border-color: $Grey_04;

  span {
    display: none;
  }
}

.unpaid_status {
  color: $Red;
  border-color: $Red;

  span {
    display: none;
  }
}

// ---------------------- / CHECK & RADIO BUTTON / -------------------------

.check_radio_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: start;
  gap: 6px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: $Green;
  }

  svg {
    width: 26px;
    border-radius: 6px;
    stroke-width: 1.7px;

  }

  .unchecked {
    color: $Grey_03;
  }

  .checked {
    color: $Green;
  }
}

// ---------------------- / NAVIGATION / -------------------------
.nav_link_box,
.nav_link_box_right {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;

  .nav_link_jp {
    font-size: 16px !important;
  }

  .nav_link {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 4px;
    border-radius: 5px;
    stroke-width: 1.5px;
    font-size: 17.5px;
    font-weight: 450;
    word-spacing: -6px;
    transition: all 0.2s ease-in-out;


    span {
      display: flex;
      flex-direction: row;
      gap: 3px;

      .nav_number {
        height: 20px;
      }
    }

  
    .nav_icon_right {
      width: 24px;
      stroke-width: 2px;
    }

    svg {
      width: 32px;
      color: $Grey_05;
    }

    .nav_number {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 22px;
      font-size: 17px;
      color: $Green;
      padding-left: 2px;

    }

    &:hover {
      color: $Green;

      svg {
        color: $Green;
        transition: all 0.2s ease-in-out;

      }
    }

  }

}

.switch {
  padding: 0;
}

// - - - - - - -  Side Menu

.nav_link_small_box {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0px;
  background-color: $White;
  border: 2px solid $Grey_03;
  border-radius: 8px;
  padding: 8px 0;
  width: 240px;

  .nav_link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 8px 10px;
    gap: 6px;
    border-radius: 5px;
    stroke-width: 1.5px;
    font-size: 17px;
    font-weight: 450;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $Green;
      background-color: $Grey_00;

      svg {
        color: $Green
      }
    }

    svg {
      width: 23px;
      stroke-width: 1.8px;
      color: $Grey_05;
    }

    .nav_number {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // width: 24px;
      // height: 23px;
      padding: 4px 6px;
      font-size: 14px;
      color: $White;
      background-color: $Green;
      border-radius: 6px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

}

// ---------------------- / COMPANY PIC / -----------------

.avatar_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: $Grey_02;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: 0px solid $Grey_01;
  // width: 88px;
  // height: 88px;

  img {
    border-radius: 8px;
    width: 92%;
    height: 92%;
    position: absolute;
  }

  .company_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
      width: 50%;
      width: 50%;
    }
  }

  .designer_icon {
    display: none;
  }

  svg {
    color: $Grey_04;
    width: 26px;
    stroke-width: 1.2px;
    width: 44px;
  }

  &:hover {
    img {
      transition: all 0.2s ease-in-out;
      width: 94%;
      height: 94%;
      position: absolute;
      border-radius: 6px;
    }

    .company_icon {
      svg {
        width: 60%;
        width: 60%;
        transition: all 0.2s ease-in-out;

      }
    }
  }

}
.admin_weblink_button {
  background-color: $Grey_00 !important;
  color:$Grey_05 !important;
  font:500 20px "M PLUS 1 Code";
  width: fit-content !important;
  border: none;
  padding: 0px 10px;
  &:hover{
    border: none;
  }
  .admin_weblink_button_icon{
    min-width:0px !important;
    width: 20px !important

  }
}
.unselectable {
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
  /* Standard */
}
.company_card_pic {
  width: 80px;
  height: 80px;

  &:hover {
    img {
      width: 84%;
      height: 84%;
    }
  }
}
.admin_company_card_pic {
  width: 60px;
  height: 60px;

  &:hover {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.company_header_pic {
  width: 56px;
  height: 56px;
  border-radius: 8px;

  img {
    border-radius: 6px;
    width: 90%;
    height: 90%;
    position: absolute;
  }

  &:hover {
    img {
      width: 84%;
      height: 84%;
      border-radius: 6px;
    }
  }
}

.designer_pic {
  border-radius: 60px;
  background-color: $Grey_00;

  img {
    border-radius: 60px;
  }

  &:hover {
    img {
      width: 84%;
      height: 84%;
      border-radius: 60px;
    }
  }
}






// ------------ SMALL ITEMS ---------

.separator {
  min-width: 1px;
  height: 24px;
  background-color: $Grey_02;
  margin: 0;
}

// ---------------------- / POPUP TA/ -----------------

.popup-tab-container {
  display: flex;
  width: 212px;
  height: 48px;
  overflow: hidden;
  border-radius: 8px;
  font-size: 20px;
  color: $Green;
  border: solid 2px $Green;
}

.tab {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  // background-color: $Grey_00;
  cursor: pointer;

}

.active-tab {
  background-color: $Green;
  color: $White;
}

//Select dropdown

.input-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  gap: 6px;
  width: 320px;

  .input-label{
    font-size: 17px;
    font-weight: 450;
    color: $Dark;
    padding: 0 12px;
    text-wrap: wrap;
}
}

.long_input{
  width: 100%;
}

.apply_link_input{
  width: 100%;
}

.input-icon {
  // padding: 0px 10px;
  // width: 50px;

  &.focused-icon {
    color: $Blue;
  }

  &.input-value-icon {
    cursor: pointer;
    transition: transform .2s ease-in-out;
    position: relative;
    display: block;
    top: 0;
    transform: rotateY(0);
  }

  &.input-value-icon:hover {
    color: $Blue;
    transform: rotate(45deg);
  }
}

.input_box {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-items: center;
  gap: 4px;
  position: relative;
  width: 100%;
}

.placeholder {
  color: $Grey_03;
}

.dropdown-select {
  padding-right: 48px;
  border: 2px solid $Grey_03;
  border-radius: 8px;
  height: 52px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  font-size: 17px;
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  

}

.selected-flag-cont-big {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

}


.select-search-input {
  border: none;
  width: 100%;
  font-size: 17px;
  width: 85%;
  background-color: transparent;
  color: #2f2f2f;
  margin: -8px;

  width: 100%;
}


.select-search-input::placeholder {
  color: #2f2f2f;

}

.select-search-input:focus {
  border: none;
  outline: none;
}

// .country_inputs{
//   padding: 0 48px 0 10px;

// }

.right-icon.focused-icon {
  color: $Blue;
}

// .input-label {
//   font-size: 17px;
//   padding-left: 6px;
//   color: $Dark;
//   transition: all 0.3s ease;
//   color: $Green;
// }

.focused .dropdown-select {
  border-color: $Blue;
}

.card_date {
  color: $Grey_06
}

.options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  background-color: #fff;
  border: 2px solid $Blue;
  border-radius: 5px;
  list-style: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}


.options .selected {
  background-color: $Grey_00;
  color: $Blue;
}

.selected-flag-cont {
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 0px 10px 0px 10px;
    width: 100%;
}

.county_flag {
  padding: 0px 0px 0px 10px;
}

.options li {
  padding: 11px 12px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

}

.options-nohover {
  cursor: unset !important;
}
.options li:hover {
  background-color: $Grey_00;
  color: $Blue;
}
.options-nohover:hover {
  background-color: #fff;
  color: #2f2f2f !important;
}

.textarea_cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 12px 0 0 0;
}

.textarea-label {
  // position: absolute;
  top: 13px;
  left: 3px;
  font-size: 17px;
  color: $Text_color;
  padding: 0 12px;
  text-wrap: wrap;
}

.textarea_label_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  p {
    color: $Dark;
    font-family: "M PLUS 1 Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 450;
    line-height: 120%;
    margin-right: 12px;
  }

  .add_format_btn {
    height: 36px;
    padding: 0 4px 0 8px;
    gap: 2px;
    font-size: 16px;
    font-weight: 500;

    svg {
      width: 24px;
      height: 24px;
    }
  }

}

.textarea_icons_cont {
  display: flex;
  gap: 6px;
  align-items: center;
}

.textarea {
  display: flex;
  height: 320px;
  padding: 12px 12px 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--Grey---05, #DEE0F2);
  background: var(--Def-White, #FFF);
  resize: none;
  padding-right: 48px;
  border: 2px solid $Grey_03;
  border-radius: 8px;
  outline: none;
  font-size: 17px;
  width: 100%;

  textarea{
  width: 100%;
  resize: none;
  height: 100%;
  outline: none;
  border: none;
  }
}
.textarea-input-cont{
  width: 100%;
}
.textarea-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.textarea_icon {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 0;
  border-radius: 6px;
  height: 30px;
  background-color: $White;
  border: 2px solid $Grey_04;
  stroke-width: 1.7px;
  font-size: 17px;
  line-height: 0%;
  width: 30px;
  color: $Grey_04;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    color: $White;
    background-color: $Green;
    border-color: $Green;
  }
}

.textarea_custom_icon {
  width: 30px;
  // color: $Grey_04;
  stroke-width: 1.5px;


}

.color,
.color_dark {
  height: 30px;
  width: 30px;
  background-color: $Green;
  border-radius: 4px;
  border: 0;

  &:hover {
    cursor: pointer;
    color: $White;
    background-color: $Green;
    border-color: $Green;
  }
}

.color_dark {
  background-color: $Dark;

  &:hover {
    background-color: $Dark;
    border-color: $Dark;
  }
}

.input-file-image {
  display: none;
}


// -------------------------- Separator
.separator_box {
  width: 100%;
  height: 2px;
  border-bottom: dashed 1px $Grey_03;
  margin: 0 0 16px 0;

}

// -------------------------- PLAN CARD
.plan_card_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 17px;
  width: 400px;

  .job_card_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-self: center;
    padding: 24px;
    gap: 16px;
    border-radius: 8px;
    border: solid 2px $Grey_02;
    width: 100%;

    .choose_plan_card_box {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-self: center;
      width: 100%;
    }

  }

  .plan_radio_card_box {
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    background-color: $Grey_white;
    border: solid 2px $Grey_white;
    width: 100%;
    border-radius: 12px;

    .plan_text_box {
      display: flex;
      // flex-direction: row-reverse;
      align-items: center;
      gap: 16px;
      width: 380px;
      font-size: 32px;
      color: $Green;

      .plan_text {
        display: flex;
        flex-direction: column;
        // align-items: start;
        gap: 2px;
        font-size: 17px;
        color: $Dark;
        width: 100%;
      }
    }

    &:hover {
      background-color: $White;
      border: solid 2px $Green;

    }

    svg {
      min-width: 44px;
      min-height: 44px;
      background-color: $White;
      border-radius: 32px;
    }
  }

}

// info-popup --------------------------------------------------
.info-popup-cont {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.info-popup {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  position: fixed;
  top: -100px; 
  left: 50%; 
  transform: translate(-50%, -20%); 
  padding: 12px 10px 12px 16px;
  max-width: 360px;
  min-width: 240px;
  gap: 12px;
  border-radius: 7px;
  transition: top 0.5s;
  z-index: 1000000;

  p{
    text-wrap: wrap !important;
    font-size: 16px;
    line-height: 104%;
  }
  svg{
    min-width: 20px;
  }
}

.info-popup-close-box {

  height: 100%;
  svg{
  height: 16px !important;
  width: 16px !important;
  stroke-width: 2.8px !important;
}}


.info-popup-close {
  max-width: fit-content;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 100%;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;


  }

}

.info-popup-close:hover {
  max-width: fit-content;
  background-color: transparent;
  border: none;
  padding: 0;
  color: $Dark;
}

.info-popup-text {
  color: #fff;
}

.info-popup-icon {
  width: 20px;
  color: #fff;
  stroke-width: 2px;
}

.info-popup-error {
  background-color: $Red;
}

.info-popup-success {
  background-color: $Green;
}
.info-popup-loading {
  background-color: $Yellow;
}

// ------------------------------------------------------------- 800

@media screen and (max-width: 800px) {
  .page_title_box {
    font-size: 28px;
  }
  .company_header_pic {
    width: 52px;
    height: 52px;
    border-radius: 40px;
  }

}

// ------------------------------------------------------------- 500

@media screen and (max-width: 500px) {
  .plan_card_box {
    width: 80%;

    .plan_radio_card_box {
      flex-direction: column;
      position: relative;

      .plan_text_box {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        gap: 8px;
        width: 100%;
        font-size: 32px;
        color: $Green;

        .plan_text {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 17px;
          color: $Dark;
          width: 100%;
        }
      }

      &:hover {
        background-color: $White;
        border: solid 2px $Green;

      }

      svg {
        min-width: 44px;
        min-height: 44px;
        background-color: $White;
        border-radius: 32px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }


  }

  .company_header_pic {
    width: 45px;
    height: 45px;
    border-radius: 40px;
  }

  .textarea_label_cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 8px;
    padding: 0 8px 6px 8px;
  }


}