@import "../styles/vars.scss";

// ---------------------------------------------- SEARCH + LIST

.page_section_box,
.page_section_box_short {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  gap: 16px;
  padding: 32px 48px;
  width: 100%;
  max-width: 1200px;

  .page_title_box {
    min-width: 100%;
    gap: 8px;
    padding: 0;

  }


  .search_list_box {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-items: center;
    gap: 4%;
    width: 100%;
    max-width: none;
    padding: 0;

    .company_card_box .company_info_box .job_info_box {
      flex-direction: row;
    }
  }
}

.page_section_box_short {
  max-width: 900px;
}

.illus {
  width: 100% !important;
  min-width: 260px !important;
  // max-width: 500px;
}
.asd{}
.entry_page_section_hero {
  .top_intro_content_box-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px;

  }

}

// - - - - - - - - - - - - - - - - - - - - - - - CONTACT FORM
.contact-form-box{

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  gap: 32px;
  padding: 32px 48px;
  width: 100%;
  max-width: 900px;

  .page_title_box {
    min-width: 100%;
    gap: 8px;
    padding: 0;
  }

form{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  gap: 16px;
  font-size: 17px !important;

}
  .contact_inputs_box{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: left;
    width: 100%;
    .input_def{
      flex: 1;
    }
  }
.textarea-cont  {
  .textarea{
    padding: 0;
    overflow: hidden;

    textarea{
      font-size: 17px;
      text-wrap: wrap !important;
      line-height: 150%;

      padding: 16px;
    }
  }
  .input-label{
   
  }
  .label_box{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px 0 12px;
  .input-letterCounter{
    color: $Grey_05;
  }
  }
}

}


.entry_page_section_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 16px;
  padding: 32px;
  width: 100%;
  max-width: 1200px;
  text-wrap: wrap !important;
  font-size: 17px;
  line-height: 180%;

  .page_title_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    gap: 5px;
    color: #00DEA3;
    font-size: 30px;
    width: 100%;
  }

  .terms {
    width: 100%;
    max-width: 820px;
    text-wrap: wrap !important;
    // padding: 0 20px;

    p,
    ul,
    li {
      text-wrap: wrap !important;
      padding: 8px 12px;

    }
    strong{
      font-size: 17px;
      font-weight: 500 !important;
      // color: $Text_color;
      color: #16c294;

    }
    br {
      display: block;
      content: "";
      padding: 0;
    }

    li ::before {
      padding-left: .45em !important;
      color: $Grey_05 !important;
      font-weight: 700 !important;
      color: #00DEA3 !important;
      background-color: aqua !important;
    }

    ol ::before {
      padding-left: 0em !important;
      color: $Green !important;
      font-weight: 450;
    }
  }

  .ql-editor {
    padding: 0 !important;
  }

  .entry_terms_actions_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 16px;
    padding: 24px 0;

    .check_radio_box svg {
      width: 32px !important;
      border-radius: 6px !important;
      stroke-width: 1.7px;
    }

    .terms-page-lock-button {
      background-color: $Grey_03;
      border-color: $Grey_03 ;
      cursor: not-allowed;
      gap: 16px;
      padding: 0 20px 0 24px;
    }

    .terms-page-unlock-button {
      background-color: $Green;
      border-color: $Green ;
      gap: 16px;
      padding: 0 20px 0 24px;
    }

  }

}


.popup_title_box_pricing{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .title_icon{
    width: 40px;
  }

}
.popup_content_box_pricing{
  .illus_pricing {
      width: 1200px;
    }
}

.content-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;

  padding: 0px 20px 20px 20px;
}


.search_list_box {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-items: center;
  gap: 4%;
  width: 100%;
  max-width: 1200px;
  padding: 32px 48px;
  z-index: 1000;
  // margin-bottom: 72px;
}

.search_card_box {
  width: 38%;
  min-width: 220px;
  position: sticky;
  top: 112px;

  .jp {
    font-size: 24px !important;
  }
}
.search_card_box_admin {
  top: 130px;
}
.mypins_box {
  padding: 0;
}

// ----------------------------------------CONTACT

.contact_inputs_box {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: left;
  width: 100%;
  flex-wrap: wrap;

  .signup_post_input {
    width: 100%;
  }

  .message {}

  .input_def {
    min-width: 45%
  }


}

// -------------------------------------------------------------------------------

// ------------------------------------------------------------- 1068

@media screen and (max-width: 1068px) {

  .page_section_box {}

  // .search_list_box {
  //   gap: 32px;
  // }

}

// ------------------------------------------------------------- ７６８


@media screen and (max-width: 768px) {

  .page_section_box {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    gap: 16px;
    width: 100%;
    max-width: 1200px;

    .search_card_box {
      position: static;
    }

    .page_title_box {
      min-width: auto;
    }

  }

  .page_section_box_short {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    gap: 16px;
    padding: 32px;
    width: 100%;
    max-width: 1200px;

  }

  .search_list_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    // gap: 48px;
    width: 100%;
    padding: 24px;

  }

  .search_card_box {
    width: 100%;
    min-width: 240px;
    max-width: 360px;
    align-items: start;
    position: static;
    padding-bottom: 40px;


    .group_input_box {
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-self: center;
      gap: 8px;
    }


  }


}


// ------------------------------------------------------------- 500

@media screen and (max-width: 600px) {
  .contact-form-box {

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    gap: 24px;
    padding: 28px;

    form {
      width: 100%;
    }

    .contact_inputs_box {
      flex-direction: column;
      display: flex;
      width: 100%;

      .input_def {
        width: 100%;
      }
    }
  }

}

