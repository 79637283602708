$Green:       #00DEA3;
$Dark:        #261147;

$Orange:      #FF6A56;
$Yellow:      #FEA300;
$FF3A81:      #FF3A81;
$Purple:      #9648DE;
$Blue:        #15A6F8;
$Text_color:  #513C6A;
$Red:         #D90D3E;

$White:       #FFFFFF;
$White_20:    #ffffff31;
$Grey_white:  #F9FAFC;
$Grey_00:     #F5F6FA;
$Grey_01:     #F0F0F9;
$Grey_02:     #ebebf9;
$Grey_03:     #DEDEEB;
$Grey_04:     #CCCEDE;
$Grey_05:     #BBBDD6;
$Grey_06:     #ABADC8;
$Grey_07:     #878BBA;


@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap');




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "M PLUS 1 Code", monospace;
  word-spacing: -1px;
  text-wrap: nowrap;

}

// br {
//   display: block;
//   content: "";
//   padding: 4px 0;
// }

a {
  cursor: pointer;
  color: $Dark;
  
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:normal;
    // position: relative;
    min-height: 100vh;
    // padding: 100px 0 200px 0;
}

// - - - - - - Scroll

::-webkit-scrollbar-track {
  background-color: transparent; 
}

::-webkit-scrollbar-thumb {
  // background-color: $Yellow;
}

::-webkit-scrollbar {
  width: 0;
}
