@import "../styles/vars.scss";

// ---------------------- / Temporary / -------------------------

#root{
  display: none ;
}
.design_sys_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 48px 48px;
  max-width: 1300px;
}

.design_sys_title_box {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  color: $Purple;
  gap: 4px;
  padding: 48px 0 24px;
  stroke-width: 1.8px;

  svg {
    width: 40px;
  }
}

// - - - - - - - 
.home_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  gap: 48px;

  .home_menu_box {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 12px;
    text-decoration: none;

    svg {
      width: 44px;
    }

    .home_nav {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      stroke-width: 1.5px;
      font-size: 24px;

      &:hover {
        color: $Green;
      }
    }
  }

  .logo_box {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 10px;

    svg {
      width: 72px;
      height: 100%;
    }
  }

}

.items_box {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 32px;
  padding: 64px 48px 48px;
  max-width: 540px;
}
.user-delete-cont {
  margin-top: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 10px;
    .user-delete-text {
        color: $Grey_05;
        font-weight: 500;
      }
  .user-delete-button {
      border: 2px solid $Grey_04;
      background-color: $White;
      color: $Grey_05;
      font-weight: 500;
      width: fit-content;
    }

}
.user-delete-cont-popup {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px 40px;

  p{
    width: 100% !important;
  }
  .user-delete-text {
    color: $Grey_05;
    font-weight: 500;
  }

            .user-delete-button {
              border: 2px solid $Grey_04 ;
              background-color: $White;
              color: $Grey_05;
              font-weight: 500;
            }
            

}


// ------------------ TABLE ----------------------

.flex-table-container {
  margin: 20px;
}

.flex-table-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.flex-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}

.flex-table-row {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.flex-table-cell {
  flex: 1;
  padding: 8px;
  border-right: 1px solid #ddd;
}

.flex-table-cell:last-child {
  border-right: none;
}

// - - - - - - -  - - - - -  -

.signup-cont {
  width: 100%;
  max-width: 1300px;
  padding: 0 8%;
}

.signup-title {
  padding: 0px !important;
}

.signup-title-cont {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.signup-username-label {
  font-family: M PLUS 1 Code;
  font-size: 18px;
  // font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.signup-username-cont {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: $Grey-00 ;
  padding: 10px 0px;
  width: 100%;
  border-radius: 8px;

  .username {
    width: 340px;
    background-color: #00DEA3;
  }
}

.signup_username_input {
  width: 31%;
}

.signup-register-cont {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  gap: 24px;
}

.signup-register-fields-right {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 10px;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.signup-register-fields {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 12px 0 32px 0;
}

.signup-register-fields-left {
  display: flex;
  // height: 300px;
  align-items: center;
}



.signup_post_input {
  width: 100%;
}

.signup-post-field-cont {
  display: flex;
  padding: 40px 0;
  border-top: 1px solid $Grey_02;
  flex-direction: column;
  gap: 24px;
}

.signup_save_button_cont {
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 8px 0;

  // .btn_def{
  //   width: 160px !important;
  // }
}

.signup_save_button {
  width: 240px !important;
}

.profile_save_button {
  width: 140px !important;
}

.post_create_input {
  width: 350px;
}

.post_create_fields {
  display: flex;
  justify-content: center;
  gap: 40px
}

.post_create_fields_left {
  display: flex;
  height: 200px;
  align-items: center;
}

.company-update-cont {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

// - - - - - - - 
.no-jobs-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 560px;
  background-color: $White;
  gap: 8px;
  font-size: 48px;
  font-weight: 600;
  list-style: 10%;
  word-spacing: -16px;
  color: $Grey_04;

  .empty-icon {
    // width: auto;
    height: 240px;
  }

}

.input_box_cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.post-flag {
  min-width: 20px;
  min-height: 20px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // scale: .7;

  svg {
    min-width: 100% !important;
    height: 100%;
    background-color: red;
    margin: 10px;
  }
}

.flag-post {
  width: 100%;
}

.post-website-link {
  display: flex;
  gap: 5px;
}

.tag-icon {
  width: 15px;
  stroke-width: 1.5px;
}

.post-city-text {
  color: var(--Grey---07, #95A1CC);
  font-family: "M PLUS 1 Code";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
  /* 21.6px */
}

.post-company-text {
  color: var(--Dark-2, #261147);
  font-family: "M PLUS 1 Code";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 26.4px */
}

.post-website-tag {
  padding: 5px 7px;
}

.post_info_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 16px;

  .input_def {
    width: 50%;
  }

  .job_title_box {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    width: 760px;
  }

  .job_workplace_box {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    width: 760px;

    .workplace {
      width: 200px !important;
    }
  }

  .salary_box {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    width: 760px;

    .salary {
      width: 22% !important;
    }

    .currency {
      width: 100px;
    }
  }

}

.post_left_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 240px;
  align-items: center;
}

.post_left_company_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 240px;
  align-items: center;
}

.post_right_cont {
  width: 664px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.post_job_name_box {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.post_job_info_box {
  flex-direction: column !important;
  align-items: start !important;
}

.post-page-cont {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 40px;
}

.post-company-card-cont {
  display: flex;
  justify-content: space-between;
}

.post-pin-cont {
  display: flex;
  gap: 10px;
  align-items: center;
}

.post-label-text {
  color: var(--Grey---06, #C2C4DA);
  font-family: "M PLUS 1 Code";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
  /* 21.6px */
}

.share-link-cont {
  display: flex;
  align-items: center;
  gap: 15px;

}

.social-media-cont {
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-job-desc {
  color: var(--Dark-2, #261147);
  font-family: "M PLUS 1 Code";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 27px */
}

.post-location-cont {
  display: flex;
  align-items: center;
  gap: 10px;

}



.post-btn-job-number {
  width: fit-content !important;
  padding: 0 12px !important;
  height: 44px !important;
  border: 0px !important;
  background-color: transparent !important;


  &:hover {

    background-color: transparent !important;
  }

  .post-job-btn-cont {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      font-size: 22px;
      font-weight: 450;
    }

  }

  .job-number {
    color: var(--Dark-2, #261147);
    text-align: center;
    font-family: "M PLUS 1 Code";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .job-btn-text {
    color: var(--Green, #00DEA3);
    text-align: center;
    font-family: "M PLUS 1 Code";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}




.pins-outer-cont {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: start;

}

.pins-inner-cont {
  max-width: 776px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup_input_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.popup_input_box_admin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: fit-content;
}

.header_Company_dropdown {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px 0px;
  border: 2px solid $Grey_03;
  border-radius: 10px;
  position: absolute;
  right: 46px;
  top: 88px;
  background-color: #fff;
  width: 180px;

  .company_dropdown_link_cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 8px 16px;
  
  }
  
  .company_dropdown_link_cont:hover {
    background-color: $Grey_02;
    width: 100%;
    cursor: pointer;
  }
  
  .company_dropdown_link_left {
    display: flex;
    font-size: 17.5px;
    font-weight: 450;
    gap: 10px;
    align-items: center;
  }

  .company_dropdown_link_icon {
    width: 23px;
    color: $Grey_04;
    stroke-width: 2px;
  }
  
  .company_nav_number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 22px;
    font-size: 18px;
    color: $Green;
    padding-left: 2px;
  
  }
  
  .company_nav_icon {
    width: 15px;
    stroke-width: 3px;
    color: $Grey_04;
  }
  


}

.jp_text{
  .company_dropdown_link_left{
    font-size: 16px;
    font-weight: 450;
}  }


.company-cards-list-box {
  border: solid 2px $Grey_02;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  // padding: 24px 0px;
  gap: 1px;
  border-radius: 8px;
  width: 100%;
  background-color: $Grey_01;
  overflow: hidden;

  .job_card_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    // border-bottom: 1px solid $Grey_01;
    background-color: $White;

    &:hover {
      background-color: $Grey_white;
      cursor: pointer;
    }

    .choose_plan_card_box {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-self: center;
      width: 100%;
    }

  }
}

.myjobs-manage-cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.myjobs-manage-inner-cont {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 8px;
  font-size: 18px;
}

.myjobs-manage-data-icon {
  width: 30px;
  color: $Grey_04;
  stroke-width: 1.5px;
}

.myjobs-manage-data {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  // padding: 0 4px;
}

.myjobs-manage-btn-edit {
  width: 36px !important;
  height: 44px !important;
  justify-content: center !important;
  padding: 5px !important;
  background-color: $White !important;
  border: $Blue 2px solid !important;

  svg {
    width: 26px;
    height: 26px;
    color: $Blue !important;
    stroke-width: 1.7px;
  }

  &:hover {
    background-color: $Dark !important;
    border: $Dark !important;
  }
}

.myjobs-manage-btn-delete {
  width: 36px !important;
  height: 44px !important;
  justify-content: center !important;
  padding: 5px !important;
  border: 2px solid $Grey_03 !important;

  &:hover {
    background-color: $Dark !important;
    border: $Dark !important;
  }

  svg {
    width: 26px;
    height: 26px;
    color: $Grey_03 !important;
    stroke-width: 1.7px;
  }
}
.myjobs-manage-btn-renew {
  width: 36px !important;
  height: 44px !important;
  justify-content: center !important;
  padding: 5px 15px !important;
  border: 2px solid $Green !important;

  &:hover {
    background-color: $Dark !important;
    border: $Dark !important;
  }

  svg {
    width: 26px;
    height: 26px;
    color: $Green !important;
    stroke-width: 1.7px;
  }
}
.myjobs-manage-btn-delete-2 {
  background-color: $Red !important;
  border: none !important;
}
.admin-functions-cont{
  display: flex;
  gap: 4px;
  justify-content: end;
  .admin-btn-approve {
    border: none;
    background-color: $Green !important;
    stroke: #fff !important;
    width: fit-content !important;
    padding: 0px 10px !important;
    width: 36px !important;
    height: 44px !important;
    justify-content: center !important;
    padding: 5px !important;
    border: 2px solid $White !important;
          
    &:hover {
      background-color: $Dark !important;
      border: $Dark !important;
    }
          
    svg {
      width: 23px;
      height: 23px;
      color: $White !important;
      stroke-width: 1.7px;
    }
    }
  .admin-btn-stop{
    background-color: $Red !important;
    border: none;
    stroke: #fff !important;
    width: fit-content !important;
    padding: 0px 10px !important ;
    width: 36px !important;
    height: 44px !important;
    justify-content: center !important;
    padding: 5px !important;
    border: 2px solid $White !important;
          
    &:hover {
      background-color: $Dark !important;
      border: $Dark !important;
    }
          
    svg {
      width: 26px;
      height: 26px;
      color: $White !important;
      stroke-width: 1.7px;
    }
 
}
}
.admin_company_card_box{
  gap: 9px !important;
}
.admin-btn-approve{
  background-color: $Green !important;
  stroke: #fff !important;
  width: fit-content !important;
  padding: 0px 7px !important ;
  svg{
    color: #fff !important;
  }
}
.soon-cont{
  display: flex;
  position:absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: 100%;
  // gap: 10px;
}
.soon-icon {
  width: 300px;
  height: 340px;
  stroke-width: 2px;
}
.soon-text {
  font-size: 54px;
  color: $Green;
  text-align: center;
  line-height: 90%;
}
.empty-icon{
  min-height: 617px;
}

.user_update_email_cont{
  display: flex;
  align-items: start;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  .user_update_email{
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: space-between;
        width: 100%;
      }
  .user_update_email_message{
      display: flex;
        align-items: start;
        gap: 2px;
        justify-content: center;
        flex-direction: column;
  }
        .user_update_email_icon {
          color: $Red;
          width: 20px;
          stroke-width: 2px;
          cursor: pointer;
        }
  p{
    padding-left: 10px;
    font-weight: 500;
    span{
      color: $Green;
    }
  }
}
.expired-post-date{
  font-size: 17px;
    font-family: M PLUS 1 Code;
    font-weight: 500 ;
    color: $Dark;
    padding: 7px 10px;
    border: solid 2px $Grey_03;
    border-radius: 5px;
    .expire-in-text{
    color: #00DEA3;
    }

}
.valid-post-date {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: M PLUS 1 Code;
  font-weight: 500 ;
  color: $Dark;
  padding: 7px 10px ;
  border: solid 2px $Grey_03;
  border-radius: 5px;
  .expired-text{
    color: red;
  }
}
.admin-valid-post-date {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: M PLUS 1 Code;
  font-weight: 500;
  color: $Dark;
  padding: 7px 10px;
  border: solid 3px $Green;
  border-radius: 5px;

  .expired-text {
    color: $Green;
  }
}
.admin-invalid-post-date {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: M PLUS 1 Code;
  font-weight: 500;
  color: $Dark;
  padding: 7px 10px;
  border: solid 3px $Green;
  border-radius: 5px;

  .expired-text {
    color: $Green;
  }
}

.unapproved-post-date{
  font-size: 17px;
  background-color: $White;
  border: 2px solid $Red;
  border-radius: 8px;
  color: $Red;
  font-weight: 500 ;
  padding: 8px 12px;
  
}
.waiting_info{
  background-color: $White;
  border: 2px solid $Red;
  color: $Red;
  padding: 10px 20px;
  border-radius: 10px;
}
// - - - - - - - - - - - - - - - - - - - - 

@media screen and (max-width: 960px) {
  .signup_username_input {
    width: 45%;
  }
}

@media screen and (max-width: 720px) {
  .signup-register-fields-right {

    .post_info_box .job_title_box {
      width: 100%;

      .job_title_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        width: 100%;
      }

    }
  }

  .no-jobs-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    background-color: $White;
    gap: 8px;
    font-size: 44px;
    font-weight: 600;
    list-style: 10%;

    color: $Grey_03;

    .empty-icon {
      width: auto;
      height: 200px;
    }

  }

  .profile_save_button {
    width: 320px !important;
  }
}

@media screen and (max-width: 500px) {
  .profile_save_button {
    width: 100% !important;
  }

  .myjobs-manage-inner-cont {
    justify-content: start;

  }
}

