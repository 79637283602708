@import "../styles/vars.scss";


.job_text {
    width: 100%;
    color: $Text_color;
    overflow-x: visible !important;
    white-space: normal !important;
    font-size: 17px !important;
    padding: 20px 40px;
    line-height: 170%;
    width: 100%;
    text-wrap: wrap;

    * {
        text-wrap: wrap;
    }

    // - - - - - - - - - -  From TextArea Style - - - - - - - - - - //


    h3,
    h4,
    h5,
    h6,
    ol,
    ul {
        text-wrap: wrap !important;
        font-weight: 450;
        line-height: 170% !important;
    }

    p {
        line-height: 170% !important;
        text-wrap: wrap !important;
        font-weight: 450;
        margin: 2px 0 !important;
        line-height: 150% !important;

    }

    h1 {
        font-size: 22px !important;
        text-wrap: wrap;
        font-weight: 500;
        line-height: 120% !important;
        margin: 2px 0 10px 0 !important;


    }

    h2 {
        font-size: 19px !important;
        text-wrap: wrap;
        font-weight: 500;
        line-height: 130% !important;

    }

    strong {
        text-wrap: wrap !important;
        // background-color: mix($Green, #fff, 20%) !important;
        // padding: 0 4px 0 4px !important;
        margin: 0 8px 0 0 !important;
        font-weight: 700;
    }

    ql-snow * {
        box-sizing: border-box;
        text-wrap: wrap;
    }

    ul li:not(.ql-direction-rtl),
    ol li:not(.ql-direction-rtl) {
        line-height: 170% !important;
        text-wrap: wrap !important;
        font-weight: 450;
        margin: 2px 0 !important;
        padding-left: 0em !important;
    }

    blockquote {
        border-left: 4px solid $Grey_03 !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        padding-left: 16px !important;
        text-wrap: wrap !important;
    }

    ol {
        padding-left: 1.4em !important;
        line-height: 170% !important;
        text-wrap: wrap !important;
    }

    ul {
        padding-left: 1.1em !important;
        line-height: 170% !important;
        word-wrap: break-word !important;
    }
    

    ol ::marker {
        padding-left: .45em !important;
        color: $Green !important; 
        text-wrap: wrap !important;
        font-weight: 450;
    }

   ul ::marker {
        font-weight: 700;
        // content: '\2022'; 
        padding-right: .2em !important;
        color: $Green !important; 
    }

}