/* styles.css */
@import "../styles/vars.scss";



.ql-editor {
    width: 100%;
    color: $Text_color;
    overflow-x: visible !important;
    white-space: normal !important;
    padding: 16px 24px !important;
    font-size: 17px !important;
    line-height: 170% !important;

    h3,
    h4,
    h5,
    h6,
    ol,
    ul {
        text-wrap: wrap !important;
        font-weight: 450;
        line-height: 170% !important;
    }

    p {
        line-height: 170% !important;
        text-wrap: wrap !important;
        font-weight: 450;
        margin: 2px 0 !important;
        line-height: 150% !important;

    }

    h1 {
        font-size: 22px !important;
        text-wrap: wrap;
        font-weight: 500;
        line-height: 150% !important;

    }

    h2 {
        font-size: 19px !important;
        text-wrap: wrap;
        font-weight: 500;
        line-height: 150% !important;

    }

    strong {
        text-wrap: wrap !important;
        // background-color: mix($Green, #fff, 20%) !important;
        // padding: 0 4px 0 4px !important;
        margin: 0 8px 0 0 !important;
        font-weight: 700;
    }



    ul li:not(.ql-direction-rtl) , ol li:not(.ql-direction-rtl) {
        line-height: 170% !important;
        text-wrap: wrap !important;
        font-weight: 450;
        margin: 2px 0 !important;
        padding-left: .7em !important;
        text-wrap: wrap;
    }

    blockquote {
        border-left: 4px solid $Grey_03 !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        padding-left: 16px !important;
        text-wrap: wrap !important;
    }

    ol,
    ul {
        padding-left: 0em !important;
        line-height: 170% !important;
        text-wrap: wrap !important;
    }

    ol ::before {
        padding-left: .45em !important;
        color: $Grey_05 !important;
        text-wrap: wrap !important;
        font-weight: 450;
    }

    ul ::before {
        font-weight: 700;
        padding-left: 0em !important;
        padding-right: .2em !important;
        color: $Green !important;
        text-wrap: wrap !important;
    }

}

.ql-snow *{
    box-sizing: border-box !important;
    text-wrap: wrap !important;
}

.quill {
    width: 100%;
    height: 500px;
}

.ql-toolbar {
    border: none !important;
    display: flex;
    justify-content: start;
    width: 100%;

    button.ql-active{
        background-color: $Green !important;
        border-color: $Green !important;
         .ql-stroke{
            stroke: $White !important;
        }
        }
}

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    flex-wrap: wrap;
    gap: 4px;
}

.ql-container {
    height: 320px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid var(--Grey---05, #DEE0F2) !important;
    background: var(--Def-White, #FFF);
    border-radius: 8px;
    outline: none;
    // font-size: 17px;
    width: 100%;
}

.ql-bold {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 70% !important;
    }

}

.ql-color-picker {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 80% !important;
        padding: 0px !important;
    }
}

.ql-picker-label {
    padding: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.ql-italic {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 80%;
    }
}

.ql-header {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 70%;
    }
}

.ql-blockquote {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 70%;
    }
}

.ql-list {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 70%;
    }
}

.ql-formats {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 6px;
    margin: 0px !important;

}

.ql-link {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    padding: 0 !important;
    border-radius: 6px !important;
    height: 30px !important;
    background-color: $White !important;
    border: 2px solid $Grey_04 !important;
    stroke-width: 1.7px !important;
    // font-size: 18px !important;
    line-height: 0% !important;
    width: 30px !important;
    color: $Grey_04 !important;
    cursor: pointer !important;

    &:hover {
        cursor: pointer;
        color: $White;
        background-color: $Green;
        border-color: $Green;
    }

    svg {
        width: 70%;
    }
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #ccc !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: block !important;
    margin-top: 4px !important;
    top: 100% !important;
    z-index: 1 !important;
}

.ql-toolbar.ql-snow .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px !important;
}

.ql-snow .ql-color-picker {

     .ql-picker-item {
        border: 0px solid transparent;
        float: left !important;
        height: 18px !important;
        margin: 2px !important;
        padding: 0px !important;
        width: 18px !important;
        border-radius: 12px;
    }

    .ql-picker-options {
        /* padding: 3px 5px; */
        width: 150px !important;
        border-radius: 20px;
    }
}

.ql-snow .ql-picker-options {
    background-color: #fff;
    display: none !important;
    min-width: 100% !important;
    padding: 4px 8px !important;
    position: absolute !important;
    white-space: nowrap !important;
}

.ql-snow a {
    color: #15A6F8 !important;
}
