@import "../styles/vars.scss";


// ---------------------- / HEADER / -------------------------
.content_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 64px;
    gap: 20px;
    width: 100% !important;
    max-width: 1300px;
    font-size: 18px;
    color: $Dark;


}

// - - - - - -  NAV

.header_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    gap: 20px;
    border-top: 8px solid $Green;
    width: 100% !important;
    height: 120px;
    position: sticky;
    // position: fixed;
    border-bottom: 1px solid transparent;
    background: #fff;
    z-index: 10000;
    transition: all 0.3s ease-in-out;

    // position: fixed;
    top: 0;
    left: 0;
    // width: 100%;
    // background-color: #f0f0f0; /* Example background color */
    /* Ensure it's above other content */

    .nav_link_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 16px;
        text-decoration: none;
        // flex-grow: 1;

        svg {
            color: $Grey_04;
        }

        .company_jobs_btn {

            border: 2px solid $Grey_02;
            padding: 5px 10px 5px 10px;
            height: 34px;
            font-size: 17px;

            .nav_number {
                font-size: 18px;
                padding-left: 0px;
            }

            span {
                flex-direction: row-reverse;
                gap: 4px;
            }

            &:hover {
                background-color: $Dark;
                border: $Dark 2px solid;

            }
        }

        .jp_text {
            font-size: 16px !important;
        }
    }

    .to_left {
        margin-right: auto;
    }

    .nav_link_box_right {
        flex-grow: 0;

    }

    // - - - - - -  LOGO
    .logo_box {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 8px;
        padding-right: 8px;
        flex-grow: 0;
        color: $Green;

        &:hover {
            color: $Dark;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

        }

        svg {
            width: 46px;
            height: 100%;

        }
    }
    .logo_box_admin{
        color: $Dark;
    }

    .logo_box_grey {
        color: $Grey_03;
        padding-right: 0;

        &:hover {
            color: $Green;
        }

        svg {
            width: 44px;
        }

    }

    // - - - - - -  RIGHT MENU
    .buttons_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        gap: 6px;
        height: 96px;

    }

    .btn_def {
        height: 46px;
        width: auto;
        padding: 0 8px 0 10px;
        gap: 4px;

        span {
            word-spacing: -4px;
        }

        svg {
            height: 28px;
        }
    }

    .btn_c_humb {
        display: none;
    }

    .buttons_box_br1020 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        gap: 20px;
        // height: 96px;
        flex-grow: 0;
    }

    // - - - - - -  Menu
    .company_info_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        text-decoration: none;
        flex-grow: 1;

    }

}

.jp_header {
    .nav_link_box {
        .nav_link {
            font-size: 17px !important;
        }
    }
}

// ---------------------- / COMPANY CARD / -------------------------


.company_card_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    gap: 16px;
    font-size: 17px;
    color: $Dark;

    // - - - - - -  Company Photo Def
    // .avatar_box {
    //     border-radius: 12px;

    //     img {
    //         border-radius: 10px;
    //     }

    //     svg {
    //         color: $Grey_04;
    //         width: 80px;
    //     }
    // }

    .company_info_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 6px;
        font-weight: 450;
        max-width: 250px;

        // - - - - - -  Job Info
        .job_name_box {
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 8px;
            width: max-content;

            span {
                width: 100% !important;
            }

        }

        // - - - - - -  Job Info
        .job_info_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            gap: 8px;

            .salary_info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-self: center;
                gap: 4px;
                color: $Green;
                font-weight: 450;
            }

            .spacer {
                width: 1.2px;
                height: 16px;
                background-color: $Grey_03;
            }
        }

        .post-page-image {
            height: auto;
        }

        // - - - - - -  COMAPNY NAME -  DEFAULT

        .company_name_box {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-self: center;
            gap: 6px;
            width: 100%;

            .company_name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-self: center;
                gap: 4px;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }

            .location_info {
                display: flex;
                flex-direction: row;
                gap: 8px;
                color: $Grey_05;
                font-weight: 450;
                align-items: center;
                width: 100%;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // width: 100%;
                }

            }

            .spacer {
                width: 1.2px;
                height: 14px;
                background-color: $Grey_04;
                margin: 0 8px;
            }

            .at {
                display: flex;
                flex-direction: row;
                align-items: end;
                justify-content: center;
                color: $Grey_04;
                height: 17px;


            }

        }
    }

}

// -------------- COMPANY NAME / LOCATION CARD

.company_pic_NameLocation,
.smaller_pic {
    gap: 10px;
    flex-direction: row !important;
    align-items: center !important;

    .avatar_box {
        width: 56px;
        height: 56px;
    }

    .company_info_box {
        .company_name_box {
            flex-direction: column;
            align-items: baseline;
            gap: 2px;
            font-size: 17px;

            .spacer,
            .at {
                display: none;
            }

        }

        .card_date {
            display: none;
        }
    }

}



// -------------- No PIC

.company_no_pic {
    .company_info_box {
        .job_info_box {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-self: center;
            gap: 5px;
        }
    }
}

// -------------- VERTICAL CARD

.company_info_ver {
    flex-direction: column;
    gap: 8px;
    font-size: 20px;

    .avatar_box {
        min-width: 100px;
        min-height: 100px;

        svg {
            width: 54px;
        }
    }

    .company_info_box {
        .company_name_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            gap: 4px;

            .spacer,
            .at {
                display: none;
            }

            .location_info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                gap: 2px;

                h5 {
                    display: none;
                }
            }
        }
    }
}

// -------------------- COMPANY INFO DETAILS

.company_info_detail {
    flex-direction: column;
    gap: 8px;

    .company_info_box {
        .job_info_box {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-self: center;
            gap: 6px;

            .spacer,
            .at {
                display: none;
            }

        }
    }
}

// -------------- HEADER COMPANY CARD

.company_header_card {
    gap: 10px;

    .company_info_box {
        .company_name_box {
            flex-direction: column;
            align-items: baseline;
            gap: 0px;

        }
    }
}

// ----------------- Only Pic 




.company_pic_small {
    gap: 0px;

    .avatar_box {
        width: 48px;
        height: 48px;


        svg {
            width: 26px;
            stroke-width: 1.6px;
        }
    }

    .company_info_box {
        display: none;
    }

}

// .company_header_pic_cont {

//     .circle {
//         height: 100%;
//         background-color: aqua;
//         padding: 8px;

//         .avatar_box {

//             img {
//                 border-radius: 50px !important;
//             }

//             .designer_icon {
//                 display: flex;
//                 stroke-width: 1.6px;
//             }

//             .company_icon {
//                 // display: none;
//             }
//         }

//     }
// }

// - - - - - - - - - - - - - - - - - JOBS LIST - CARDS
.btn_c_icn_yellow {
    background-color: $Yellow !important;
}

.job_cards_list_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .pagination-btn {
        justify-content: center;
        padding: 0;
        width: 40px;
        height: 40px;

        svg {
            width: 24px;
            height: 24px;
            stroke-width: 2.5px;
        }
    }

    .pagination-btn-more {
        width: fit-content !important;
        gap: 4px !important;
        padding: 10px 6px 10px 16px;
        height: 46px;
    }

    .pagination-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        font-size: 32px;
        font-weight: 500;
        color: $Grey_04;

        .no_more {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            svg {
                width: 32px;
                stroke-width: 1.4px;
            }
        }
    }
}

.job_cards_list_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    background-color: $White;
    border: solid 1px $Grey_03;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    justify-content: center;

    .job_card_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 24px 24px;
        width: 100%;
        background-color: $White;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $Grey_white;
        }

        .job_card {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            // padding: 32px 20px;
            gap: 8px;
            // width: 100%;
            height: 100%;
        }

        .job_actions_box {
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            // padding: 32px 20px;
            gap: 12px;
            height: 100%;
            // width: 140px;
            color: $Grey_04;

            .btn_c_icn_yellow {
                background-color: $Yellow !important;
            }

            .btn_c_icn {
                justify-content: center;
                height: 44px;
                width: 34px;
                background-color: $Grey_02;
                border: 0;

                &:hover {
                    background-color: $Yellow;

                }

            }

            span {
                font-size: 13px;
                background-color: aqua;
            }

        }

    }

}


// - - - - - - - - - - - - - - - - - - -  COMPANY INFO FOR DETAIL PAGE

.company_page_detail_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.job_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-self: baseline;
    // gap: 8px;
    width: 100%;
    border: 1px solid $Grey_02;
    border-radius: 12px;
    overflow: hidden;

    .company_details_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 32px;
        width: 100%;
        border-bottom: 1px solid $Grey_02;

        .company_detail_info_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .company_info_box {
                // height: 100px;
                justify-content: center;

                .job_name_box {
                    flex-direction: row;
                }
            }

            .job_actions_box {
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
                gap: 16px;
                height: 100%;
                color: $Grey_04;

                .pin_it {
                    justify-content: center;
                    height: 48px;
                    width: 38px;
                    background-color: $Grey_02;
                    border: 0;
                    top: 16px;
                    right: 16px;

                    &:hover {
                        background-color: $Yellow;
                    }
                }
            }
        }

        .company_sns_box {
            display: flex;
            flex-direction: row;
            align-items: end;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            // padding: 20px 32px;
            color: $Grey_05;
            font-size: 17px;
            font-weight: 450;


            .apply_btn {
                width: 140px;
                height: 48px;
                padding: 0 8px 0 12px;

                svg {
                    width: 27px;
                    height: 27px;
                    stroke-width: 1.7px;
                }
            }


            span {
                word-spacing: -4px;
            }

            .footer_sns_box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                gap: 8px;
                width: 240px;
                font-size: 16px;

                svg {
                    width: 28px;
                    stroke-width: 1.7px;
                    color: $Grey_03;

                    &:hover {
                        color: $Green;
                    }
                }
            }
        }
    }

}

.admin-tab-changer{
    display: flex;
    border-radius: 12px;
    border: 3px solid $Dark;
    width: 100%;
    align-items: center;
    height: 50px;
    overflow: hidden;

    .admin-tab-company{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "M PLUS 1 Code";
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .admin-tab-job{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "M PLUS 1 Code";
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .admin-tab-selected{
        background-color: $Dark;
        color: $White;
        }
}
.myjobs-manage-data-companies{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid $Grey_03;
        padding: 5px 5px;
        border-radius: 10px;
            font: 500 15px "M PLUS 1 Code";
        
        
        .myjobs-manage-data-companies-green {
            color: $Green;
                }
        .myjobs-manage-data-companies-red {
            color: $Red;
                }
        .myjobs-manage-data-companies-grey {
            color: $Grey_05;
                }

                
            

}

.vertical {
    flex-direction: row;
    align-items: start;
    justify-self: baseline;


    .company_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: baseline;
        gap: 24px;
        width: 30%;
        padding-top: 40px;
        position: sticky;
        top: 80px;

        .small_link_btn {
            max-height: 26px !important;
            width: 58px !important;
            height: 40px !important;
            font-size: 15px !important;
            border-radius: 5px !important;
            gap: 2px !important;
            padding: 0 5px 0 7px !important;
            color: $Grey_05 !important;
            border-color: transparent !important;
            background-color: $Grey_00 !important;

            &:hover {
                background-color: $Dark !important;
                color: $White !important;
                border: 2px solid $Dark !important;

                p {
                    color: $White !important;
                }

                svg {
                    color: $White !important;
                }

            }
        }

        .post-btn-job-number {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            gap: 2px;
            padding: 0 8px 0 3px !important;
            height: 42px !important;
            border: 2px solid $Grey_02 !important;
            border-radius: 8px;
            cursor: pointer;

            svg {
                width: 20px !important;
                height: 20px;
                stroke-width: 2.2px;
                color: $Grey_04;
            }

            &:hover {
                background-color: $Dark !important;
                color: $White !important;
                border: 2px solid $Dark !important;

                p {
                    color: $White !important;
                }

                svg {
                    color: $White !important;
                }

            }

            .post-job-btn-cont {
                display: flex;
                align-items: center;
                gap: 4px;

                p {
                    font-size: 22px;
                    font-weight: 450;
                }

            }

            .job-number {
                color: var(--Dark-2, #261147);
                text-align: center;
                font-family: "M PLUS 1 Code";
                font-size: 20px !important;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .job-btn-text {
                color: var(--Green, $Green) !important;
                text-align: center;
                font-family: "M PLUS 1 Code";
                font-size: 20px !important;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }


    }

    .smaller_pic {
        display: none;

        .avatar_box {
            width: 48px;
            height: 48px;
        }
    }


}

// - - - - - - - - - - - - -  COMPANY ADMIN CARD
.job_card_box {

    // &:hover {
    //     cursor: auto;
    // }

    .static_actions_box {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        gap: 12px;
        height: 120px;

        .static_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            font-size: 17px;
            gap: 10px;

            .static {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 4px;
                font-weight: 450;
            }

            svg {
                width: 32px;
                stroke-width: 1.3px;
                color: $Grey_05;
            }
        }

  
        .admin_btn_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 8px;

            .admin_btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 36px;
                height: 36px;
                padding: 3px;
                background-color: $Blue;
                border-color: $Blue;
            }

            .btn_c_line {
                background-color: $White;
                color: $Grey_04 !important;
                border-color: $Grey_04;
            }

            .btn_c_icn {
                &:hover {
                    background-color: $Dark;
                    border-color: $Dark;
                }
            }

        }
    }
}
        .admin-info-jobs-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3px;
            border: 2px solid $Grey_03;
            padding: 5px 10px ;
            border-radius: 10px;
                .admin-info-jobs {
                        font: 500 20px "M PLUS 1 Code";
                        color: $Dark;
                
                    }
                
                    .admin-info-jobs-green-text {
                        color: $Green;
                        font: 500 15px "M PLUS 1 Code";
                
                    }
        }
// - - - - - - - - - - - - - SEARCH CARD
.search_card_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-self: center;
    font-size: 17px;
    gap: 16px;
    width: 268px;

    .input-label {
        padding-left: 8px;
        font-size: 18px;
        color: $Green !important;
    }

    .input-container {
        width: 100%;

    }

    .currency {
        width: 120px;
    }

    .location_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-self: center;
        gap: 12px;
        width: 100%;



        .location_radiobtn_box {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: start;
            gap: 8px;
            width: 100%;
            padding: 0 8px;
            flex-wrap: wrap;
        }
    }

    .salary_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-self: center;
        gap: 8px;
        width: 100%;

    }

    .group_input_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-self: center;
        gap: 8px;
        width: 100%;
    }

    .currency_box {
        flex-direction: row;
    }

    .clear {
        width: auto;
        height: 48px;
    }
}


.jp_text {
    .input-label {
        font-size: 17px;
    }
}

// ----------------------------------------- POPUPS
.popup-cont {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100000;
}

.popup_lightbox_white {
    background-color: #fff !important;
}
.popup_lightbox_admin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 40px 16px;

        .popup_title_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            font-size: 28px;
            font-weight: 500;
            gap: 8px;
            color: $Green;
            margin-bottom:40px ;
            SVG {
                width: 40px;
                stroke-width: 1.7px;
                color: $Grey_04;
                display: none;
            }
        }
}

.popup_lightbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #261147e8;
    padding: 40px 16px;

    .popup_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        gap: 16px;
        border-radius: 20px;
        padding: 32px 0 40px 0;
        position: relative;
        background-color: $White;
        font-size: 18px;
        color: $Dark;
        width: 100%;
        max-width: 500px;

        .popup_close_box {
            position: absolute;
            top: 8px;
            right: 8px;

            .btn_c_icn {
                width: 48px;
                height: 48px;
                padding: 0;
                background-color: transparent;
                border: transparent;
                color: $Grey_03;

                &:hover {
                    color: $Green;
                }

                svg {
                    width: 30px;
                    height: 30px;
                    stroke-width: 2px;
                }
            }
        }

        .popup_title_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            font-size: 28px;
            font-weight: 500;
            gap: 8px;
            color: $Green;

            SVG {
                width: 40px;
                stroke-width: 1.7px;
                color: $Grey_04;
                display: none;
            }
        }

        .popup_content_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            gap: 8px;
            width: 100%;
            text-wrap: wrap;
            text-align: center;

            .illus {
                width: auto;
                height: 200px;
            }

            .form_box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-self: center;
                gap: 16px;
                width: 88%;
                max-width: 380px;

                .page_title_box {
                    align-items: center;

                    h5 {
                        text-align: center;
                        width: 100%;
                        text-wrap: wrap;
                    }

                    h4 {
                        text-align: center;
                        width: 100%;
                        text-wrap: wrap;
                    }
                }

                .input_box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-self: center;
                    gap: 8px;
                    width: 100%;
                }

                .input-container {
                    width: 100%;
                }
            }
        }

        .buttons_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            gap: 8px;
            width: 100%;
            font-size: large;
        }

        .popup_content_box_temp {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            gap: 8px;
            width: 100%;
            text-align: center;
            padding: 0 32px;
            
            .popup_txt{
                font-size: 17px;
                text-wrap: wrap;
                line-height: 130%;
            }

            p {
                width: 90%;
                text-wrap: wrap;
                line-height: 160%;
                margin: 16px;
            }

            .illus {
                width: auto;
                height: 170px;
            }

        }
    }

    .pricing {
        width: 90%;
        max-width: 760px;

        .popup_content_box {
            gap: 32px;
            width: 100%;
        }

    }

    // - - - - - - - - - - -  PRICING CARD
    .price_cards_box {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-self: center;
        gap: 12px;
        width: 100%;

        .price_card {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-self: center;
            gap: 4px;
            border-radius: 12px;
            // background-color: $Grey_00;
            border: solid 2px $Grey_02;
            width: 100%;
            font-size: 18px;
            font-weight: 450;
            padding: 16px 20px;
            position: relative;

            h8 {
                font-size: 32px;
                color: $Green;
            }

            svg {
                width: 48px;
                stroke-width: 1.3px;
                color: $Grey_03;
                position: absolute;
                top: 8px;
                right: 10px;
            }

            span {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: 4px;

                h9 {
                    font-size: 22px;
                    color: $Green;
                }
            }
        }
    }
}

// - - - - - - - - - - - - - TOP INTRO

.top_intro_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/icons/btn_bg.svg');
    width: 100%;


    .top_intro_content_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 56px;
        gap: 5ch;
        width: 100%;
        max-width: 1100px;
        // min-height: 360px;
        flex-wrap: wrap;

        .top_intro_left {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            font-size: 66px;
            line-height: 90%;
            font-weight: 600;
            color: $Green;

            span {
                color: $Dark;
            }
        }

        .jp {
            font-size: 60px !important;
            line-height: 116%;
            font-family: "M PLUS 1 Code";
            font-optical-sizing: auto;
            font-weight: 900;
            letter-spacing: -2px;
        }

        .top_intro_right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .illus {
                width: 260px;
                height: auto;
            }
        }

    }


    .top_intro_content_box-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 32px;
        gap: 5ch;
        width: 100%;
        max-width: 1100px;
        flex-wrap: wrap;

        .top_intro_left {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            gap: 4px;
            font-size: 56px;
            line-height: 90%;
            font-weight: 600;
            color: $Green;

            span {
                color: $Dark;
                word-spacing: -12px;
            }

            h7 {
                color: $Grey_04;
            }
        }

        h8 {
            word-spacing: -12px;
            text-wrap: wrap;

            span {
                color: $Green !important;
            }
        }


        .top_intro_right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .illus {
                width: 240px !important;
                min-width: 200px !important;
                height: auto;
            }
            .illus-2 {
                width: 380px !important;
            }
        }

    }


}

// - - - - - - - - - - - - - - - - - - FOOTER
.footer {
    width: 100%;
    // bottom: 0;
    // left: 0;
    margin-top: auto;
    background-color: #f0f0f0;
    /* Example background color */
    z-index: 100;

    .footer_box {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-items: center;
        padding: 32px 48px;
        // gap: 16px;
        background-color: $Dark;
        width: 100%;
        // height: 200px;
        font-size: 16px;
        color: $Green;
        // margin-top: 64px;
        position: relative;
        bottom: 0px;

        .footer_top_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 32px;
            width: 100%;
            // max-width: 1300px;


            .footer_logo_box {
                gap: 8px;
                display: flex;
                flex-direction: row;
                width: 88px;
                min-width: 38px;
                height: auto;
                color: $Green;
                cursor: pointer;

                &:hover {
                    color: $White;
                }

            }

            .footer_text_box {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 12px;
                white-space: nowrap;
                font-size: 16px;



                .footer_text_bloc_box {
                    display: flex;
                    flex-direction: row;
                    align-items: start;
                    // width: 160px;
                    gap: 10px;

                    h4 {
                        min-width: 112px;
                    }

                    .footer_links_box {
                        display: flex;
                        flex-direction: row;
                        align-items: start;
                        gap: 6px;

                        span {
                            color: $White_20;
                        }
                    }

                    .footer_nav {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        gap: 12px;
                        list-style-type: none;
                        padding: 0;
                        text-decoration: none;
                        color: $White;

                        &:hover {
                            color: $Green
                        }

                        svg {
                            width: 16px;
                            stroke-width: 2.2px;
                            color: $Green;
                        }

                        span {
                            color: $Grey_01;
                        }

                    }

                }


                .footer_copy_box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;
                    gap: 4px;
                    font-size: 16px;
                    color: $White_20;
                    line-height: 100%;

                    span {
                        font-size: 24px;
                        font-weight: 300;
                        padding-top: 7px;
                    }
                }

                h4 {
                    font-weight: 450;
                    // padding-right: 12px;
                }
            }

            .jp_text {
                .footer_text_bloc_box {
                    font-size: 14.5px;
                    letter-spacing: .4px;
                }
            }

        }

        .footer_bottom_box {
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            gap: 16px;
            // width: 100%;


            .footer_sns_box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                gap: 10px;
                width: 240px;


                svg {
                    width: 28px;
                    stroke-width: 1.5px;
                    color: $White_20;

                    &:hover {
                        color: $Green;
                    }
                }
            }

            .footer_policy_box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                gap: 8px;
                font-size: 15px;
                color: $White_20;


                .footer_policy {
                    text-decoration: none;
                    color: $White_20;

                    &:hover {
                        color: $Green;
                    }

                }

            }

        }

        // - - - - - - - - LANG
        .lang_btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 44px;
            font-size: 17px;
            font-weight: 450;
            background-color: transparent;
            border-color: $Green;
            // background-color: $Green;
            padding: 0 12px 0 6px;
            color: $Green;
            word-spacing: -30px !important;
            letter-spacing: .6px;
            position: absolute;
            top: 34px;
            right: 32px;

            svg {
                color: $Green !important;
            }

            &:hover {
                background-color: $Green;
                color: $Dark ;

                svg {
                    color: $Dark !important;
                }
            }
        }

    }


    .footer_box_simple {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-items: center;
        padding: 32px 48px;
        gap: 16px;
        background-color: $Dark;
        width: 100%;
        height: auto;
        font-size: 16px;
        color: $Green;
        // margin-top: 64px;
        // position: absolute;
        bottom: 0px;

        .footer_top_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 32px;
            width: 100%;
            // max-width: 1300px;


            .footer_logo_box {
                gap: 8px;
                display: flex;
                flex-direction: row;
                width: 80px;
                min-width: 38px;
                height: auto;
                color: $Green;
                cursor: pointer;

                &:hover {
                    color: $White;
                }

            }
        }
    }
}

// .footer_mockup {
//     height: 240px;
//     width: 100%;
// }

// - - - - - - - - - - - - - - - - - - - SIGN UP FORM

.username_input_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 14px 20px;
    gap: 16px;
    font-size: 17px;
    background-color: $Grey-00;
    border-radius: 8px;

    .input-container {
        width: 360px;
    }
}

.company_info_input_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;
    padding: 0;
    // margin-bottom: 64px;


    .company_image_box {
        width: 100%;

        .image_upload_cont {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-items: center;
            gap: 16px;
            align-items: center;
            width: 100%;

            .signup-upload-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
                font-size: 15px;
                font-weight: 500;
                padding: 8px 9px;
                width: fit-content;
            }
        }
    }

    .company_info_inputs_box {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
        gap: 16px;
        flex-wrap: wrap;
        padding-bottom: 8px;
    }
}

.job_info_input_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 24px;
    width: 100%;
    // margin-bottom: 64px;

    .job_info_inputs_box {

        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
        gap: 8px;
        flex-wrap: wrap;
        width: 100%;

        .input-container {
            // width: 45%;
            // min-width: 320px;
            width: 300px;
        }

        .workspace_input {
            max-width: 200px;
        }

        // .salary_input {
        //     max-width: 200px;
        // }

        .salary_input_cont {
            width: 200px;
        }

        .currency {
            max-width: 96px !important;
        }
    }
}

.flag {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: aqua;
}

.input-pretext {
    // padding: 0 10px 0 12px;
    color: $Grey_05;
    font-size: 17px;
}

// .header-mockup {
//     height: 120px;
//     width: 100%;
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Admin Header
.admin-header{
    align-items: center;
    height: 140px;
}
.admin-panel{
    display: flex;
    gap: 7px;


.admin-panel-info-cont {
        display: flex;
        align-items: end;
        flex-direction: column;
        border: 3px solid $Grey_03;
        padding: 5px 10px;
        border-radius: 10px;

        

        .admin-panel-info-icon {
            width: 34px;
            color: $Grey_05;
            stroke-width: 2px;

          
        }
        .color-black {
            color: $Dark;
            stroke-width: 1.5px;
                }

        .admin-panel-info-title {
            font: 500 18px "M PLUS 1 Code";
        }

        .admin-panel-info-number {
            font: 500 18px "M PLUS 1 Code";
        }
    }

        .border-black {
            border: 3px solid $Dark;
                }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  - - - - 1030

@media screen and (max-width: 1030px) {
    .content_box {
        padding: 24px 48px;
    }

    .header_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        padding: 32px;
        gap: 24px;
        width: 100%;
        height: 100px;
        max-width: 1400px;

        .buttons_box {
            display: none !important;

            .btn_c_humb {
                display: none;
            }
        }

        // - - - - - -  Menu
        .nav_link_box,
        .nav_link_box_right {
            display: flex;
            flex-direction: row;
            align-items: baseline !important;
            text-decoration: none;
            font-size: 16px;
            // flex-grow: 1;

            .nav_icon_right {
                display: none;
            }

            svg {
                width: 30px;
            }

            .nav_link {
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1px;
                stroke-width: 1.5px;
                font-size: 16px !important;
                font-weight: 480;


                &:hover {
                    color: $Green;
                }
            }
        }

        .nav_link_box_right {
            flex-grow: 0;

            .nav_link {
                // flex-direction: column !important;
            }
        }


    }

    // .footer_box {

    //     .footer_top_box,
    //     .footer_bottom_box {
    //         padding: 0 32px;
    //     }
    // }

    .company_card_box .company_info_box .job_info_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        gap: 8px;

    }


}



// ----------------------------------------------------------- 800

@media screen and (max-width: 800px) {

    .nav_link_box .nav_link {
        text-decoration: none;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        gap: 1px;
        stroke-width: 1.5px;
        font-size: 16px !important;
        font-weight: 480;
    }

    .content_box {
        padding: 24px 32px;
    }

    // .footer_mockup {
    //     height: 380px;
    // }

    .buttons_box_br1020 {
        display: flex;
        flex-direction: co;
        align-items: center;
        justify-self: center;
        gap: 6px;
        height: 96px;
        flex-grow: 0;



        .btn_def {
            display: flex;
            padding: 12px;

            svg {
                min-width: 2px;
            }
        }

        span {
            // display: none;
        }

    }

    .header_box .btn_c_humb {
        display: none;

    }

    // - - - - - -  LOGO
    .logo_box {
        // width: 48px;

        .logo_mark {

            display: none;
        }
    }

    .popup_lightbox {
        .price_cards_box {
            display: flex;
            flex-direction: column;
            padding: 24px;
        }
    }

    // -- - - - - - - -  TOP INTO
    .top_intro_box {

        .top_intro_content_box {
            display: flex;
            // flex-direction: column-reverse;
            align-items: center;
            // justify-content: space-between;
            padding: 40px;
            gap: 16px;
            width: 100%;
            max-width: 1100px;
            // min-height: 360px;

            .top_intro_left {
                font-size: 64px;
                line-height: 90%;
                font-weight: 600;
                color: $Green;

                span {
                    color: $Dark;
                }
            }

            .top_intro_right {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .illus {
                    width: 220px;
                    height: auto;
                }
            }

            .jp {
                font-size: 50px !important;
                line-height: 116%;
                font-family: "M PLUS 1 Code";
                font-optical-sizing: auto;
                font-weight: 900;
            }

        }

        .top_intro_content_box-2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 36px;
            gap: 2ch;
            width: 100%;
            max-width: 1100px;
            flex-wrap: wrap;

            .top_intro_left {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                gap: 4px;
                font-size: 54px;
                line-height: 90%;
                font-weight: 600;
                color: $Green;

                span {
                    color: $Dark;
                    word-spacing: -12px;
                }

                h7 {
                    color: $Grey_04;
                }
            }

            h8 {
                word-spacing: -12px;
                text-wrap: wrap;
                width: 244px;

                span {
                    color: $Green !important;
                }
            }


            .top_intro_right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .illus {
                    width: 200px;
                    height: auto;
                }
            }

        }
    }

    // - - - - - - - - - - - FOOTER
    .footer {
        .footer_box {
            // padding: 48px 32px 24px 32px;
            flex-direction: column;
            align-items: end;
            height: auto;
            // margin-top: 64px;
            // gap: 60px;
            padding: 32px;

            .footer_top_box {
                flex-direction: column;
                align-items: start;
                gap: 40px;

                .footer_logo_box {
                    flex-direction: row;
                    width: 96px;
                    height: auto;
                }

                .footer_text_box {
                    gap: 16px;
                }

            }

            .footer_bottom_box {
                // flex-direction: column-reverse;
                align-items: end;
                justify-content: right;

                .footer_copy_box {
                    justify-content: right;
                    width: 100%;
                }
            }

        }

    }

    .company_info_input_box {
        flex-direction: column;
        width: 100%;

        .image_upload_cont {
            flex-direction: row;

            .signup-upload-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto !important;
                // width: 72px !important;
                font-size: 17px;
                padding: 8px 9px !important;
            }
        }


        .company_info_inputs_box {
            align-items: start;
            justify-content: start;
            width: 100%;
            // .input-container {
            //     width: 100%;
            //     min-width: auto;
            // }



        }

    }

    .company_details_box {
        padding: 20px;

        .company_detail_info_box {
            align-items: end;

            .company_info_box {
                height: auto;
                justify-content: center;
            }
        }
    }

    .company_2info_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
        // background-color: #F9FAFC;
        border-radius: 8px;
    }

    .vertical {

        .company_box {
            display: none;
        }

        .company_info_detail {}

        .smaller_pic {
            display: flex;
            flex-direction: column;
            align-items: start;
        }

    }

    // .header-mockup {
    //     height: 100px;
    //     width: 100%;
    // }

    .vertical .job_box .job_text {
        line-height: 150%;
        // padding: 20px;
    }
}

// ------------------------------------------------------------- 688

@media screen and (max-width: 654px) {
    .content_box {
        padding: 20px;
    }

    .header_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 14px 20px;
        gap: 8px;
        height: 76px !important;
        border-bottom: solid 1px $Grey_03;

        .buttons_box {
            display: none !important;
        }

        .btn_c_humb {
            // display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            gap: 0;
            padding: 0;
            width: 56px;

            svg {
                width: 36px;
                display: flex;

            }
        }

        .buttons_box_br1020 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            gap: 10px;
            height: 96px;
            flex-grow: 0;
            text-align: center;
            // margin-left: auto;
            height: 44px;

            .btn_def {
                display: flex;
                height: 44px;
                padding: 10px 5px;
                font-size: 16px;
                font-weight: 480;

                svg {
                    display: none;
                }

                span {
                    // display: none !important;
                }
            }

            .hide_post_btn {
                display: none !important;
            }


        }

        // - - - - - -  Menu
        .nav_link_box {
            gap: 12px;
            padding-left: 0px !important;
            flex-grow: 0;
            margin-right: auto;

            svg {
                width: 28px !important;
            }

            .nav_link {
                flex-direction: column;
                font-size: 15px !important;
                text-align: center;
                flex-grow: 1;
                margin-right: auto;
                gap: 0;

                span .nav_number {
                    height: 18px;
                    font-size: 15px;
                }

            }

            .hide_on_mobile {
                display: none;
            }
        }

        .nav_link_box_right {
            display: none;
        }

        .hide_box_on_mobile {
            // display: none;
        }

        // - - - - - -  LOGO
        .logo_box {
            width: 48px;
            align-self: center;

            .logo_mark {

                display: none;
            }
            
        }

        .avatar_box {


            svg {
                color: $Grey_04;
                width: 34px;
            }
        }

        .btn_c_humb {
            width: 40px;
            height: 48px;
            display: flex;
            align-self: flex-end;
            background-color: $Grey_00;

            svg {
                width: 32px;
                stroke-width: 2px;
                display: flex !important;

            }
        }

        .company_card_box .company_info_box {
            gap: 12px;

            .job_info_box {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-self: center;
                gap: 8px;

                .spacer {
                    display: flex;
                }
            }


            .small_link_btn {
                max-height: 30px;
                // width: 30px;
                font-size: 16px;
                border-radius: 6px;
                gap: 4px;
                padding: 7px;

                span {
                    display: none;
                }
            }
        }
    }

    .vertical .job_box .job_text {
        line-height: 150%;
        padding: 24px;
    }

    .company_header_card .company_info_box .company_name_box {
        flex-direction: column;
        align-items: baseline;
        gap: 0px;
        font-size: 16px;
    }

    .header_box .logo_box_grey {
        color:$Grey_03;
        width: 38px !important;
    }

    .company_pic_NameLocation,
    .smaller_pic {
        gap: 6px;

        .avatar_box {
            width: 44px;
            height: 44px;

            svg {
                width: 26px;
                stroke-width: 1.6px;
            }
        }

        .company_info_box {
            .company_name_box {
                font-size: 18px !important;
                gap: 0px;

            }
        }
    }

    // - - - - - - - - - - - - - - - - - - - - POPUPS


    .username_input_box {
        flex-direction: column;
        padding: 12px 5% 20px 5%;
        gap: 12px;

        .input-container {
            width: 100%;
            max-width: 360px;
        }

    }

    .company_page_detail_box {}

    .company_detail_info_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        gap: 16px;
        width: 100%;
        border-radius: 8px;

        // .pin_it {
        //     position: absolute;
        // }

        .company_info_box {
            height: auto;
            justify-content: center;

            .job_name_box {
                flex-direction: row;
            }
        }


        .job_actions_box {
            width: 100% !important;

            .apply_btn {
                width: 100% !important;

                .company_name_box {
                    font-size: 18px !important;
                }
            }
        }
    }

    // .header-mockup {
    //     height: 112px;
    //     width: 100%;
    // }

    .job_box .company_details_box {
        padding: 24px;
    }

}



// ------------------------------------------------------------- 500

@media screen and (max-width: 500px) {
    // .footer_mockup {
    //     height: 460px;
    // }

    .job_box .company_details_box {
        padding: 24px 16px;
    }

    .job_cards_list_box {
        width: 100%;

        .job_card_box {
            flex-direction: column;
            align-items: start;
            padding: 20px;
            gap: 8px;
            position: relative;

            .job_actions_box {
                width: 100%;
                padding: 0 4px;
                align-items: start;

                .btn_c_icn {
                    justify-content: center;
                    height: 48px;
                    width: 48px;
                    background-color: #ebebf9;
                    border: 0;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }

            .static_actions_box {
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                height: auto;
                width: 100%;
                margin-top: 12px;

                .admin_btn_box {
                    flex-direction: row-reverse;

                    .admin_btn {
                        width: 44px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .company_card_box {
        display: flex;
        flex-direction: column;
        align-items: start;

        .company_info_box {
            padding: 0;
        }
    }

    .company_info_detail_box {
        padding: 32px;
        height: auto;

        .job_actions_box {
            flex-direction: column-reverse;
            align-items: end;

            .apply_btn {
                width: 80px;
                height: 52px;

                svg {
                    display: none;
                }
            }

            .btn_c_icn {
                width: 80px;
                height: 52px;
            }
        }
    }

    // - - - - - - - - - - -  FOOTER
    .footer {
        .footer_box {
            // padding: 48px 32px 24px 32px;
            height: auto;
            // margin-top: 64px;
            gap: 0px;

            .footer_top_box {
                flex-direction: column;
                gap: 24px;

                .footer_logo_box {
                    width: 100px;
                }

                .footer_text_box {
                    flex-wrap: wrap;
                    gap: 20px 20px;

                    span {
                        padding-bottom: 4px;
                    }

                    .footer_text_bloc_box {
                        flex-direction: column;
                        gap: 10px;

                    }
                }

            }



            .footer_bottom_box {
                flex-direction: column-reverse;
                align-items: center;
                gap: 20px;
                justify-content: center;
                width: 100%;

                .footer_copy_box {
                    justify-content: center;
                    width: 100%;
                }

                .footer_sns_box {
                    justify-content: center;
                    gap: 12px;

                    svg {
                        width: 34px;
                        stroke-width: 1.5px;
                        color: $White_20;

                        &:hover {
                            color: $Green;
                        }
                    }

                }
            }

        }
    }

    .job_box .company_details_box .company_sns_box {

        .footer_sns_box svg {
            width: 30px;
        }
    }

    .company_info_input_box {
        width: 100%;

        .company_info_inputs_box {
            flex-wrap: wrap;

            .input-container {
                width: 100%;
            }

            .hide_input_transparent {
                display: none;
            }
        }

        .designer_image_box {
            border-radius: 100px;
        }
    }



    .vertical .job_box .job_text {
        line-height: 150%;
        padding: 20px;
    }

    .company_detail_info_box {
        flex-direction: column;
        gap: 8px;
    }

    .company_sns_box {
        display: flex;
        flex-direction: column;
        align-items: start;

        .footer_sns_box {
            justify-content: start;

        }
    }

    // .header-mockup {
    //     height: 112px;
    //     width: 100%;
    // }

    .job_info_input_box .job_info_inputs_box {

        .input-container,
        .workspace_input {
            min-width: 100%;
        }
    }

}

.image_upload_cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.image_upload_frame {
    overflow: hidden;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 12px !important;
    background: $Grey_00;

}

.user_image_upload {

    .image_upload_frame {
        border-radius: 60px !important;
    }
}

.designer_image_box {
    border-radius: 100px !important;
}

.image_upload_icon {
    width: 50px;
    height: 50px;
    color: $Grey_04;
}

.image_cont_horizontal {
    flex-direction: row;
}